<template>
  <div
    class="phase-tag relative flex h-6-5 py-1 mr-0-5 rounded text-center text-xs mb-1 text-white font-semibold flex-grow justify-center min-w-25"
    :class="[
      isCurrentOrPrior ? 'active' : 'inactive',
      disablePhaseChange ? 'cursor-not-allowed ' : 'cursor-pointer',
      { 'highlight-white': (isCurrentOrPrior && isActive) || !isBuilderView },
      { highlight: !isCurrentOrPrior && isActive }
    ]"
    :data-cy="`phase-tag-${phaseName}`"
    @mouseenter="showIconsWithTooltip = true"
    @mouseleave="showIconsWithTooltip = false"
  >
    <div
      v-if="!first"
      class="arrow-negative z-10 absolute mt-5-5 left-0 w-0 h-0"
    />
    <div class="uppercase whitespace-nowrap items-center pl-2 pr-1">
      <icon-base
        v-if="showDeadIcon"
        class="mr-2"
        height="12"
        width="12"
        icon="fail"
        data-cy="icon-dead"
      />
      <icon-base
        v-else-if="showCompletedIcon"
        class="mr-2 text-checkmark-green"
        height="12"
        width="12"
        icon="success-round"
        data-cy="icon-complete"
      />
      {{ formattedPhaseName(phaseName) }}
    </div>
    <div class="absolute right-1" :class="[dynamicIconsStyles]">
      <div
        v-if="isManagedByLendflow || isManagedByClient"
        v-tooltip="tooltipText"
      >
        <icon-base
          v-if="isManagedByLendflow"
          height="12"
          width="12"
          :icon="lendflowIconStyle"
          viewBox="0 0 20 30"
        />
        <template v-if="isManagedByClient">
          <icon-base
            v-if="isManagedByLendflow && isManagedByClient"
            height="14"
            width="14"
            :icon="plusIconType"
            viewBox="0 0 60 60"
          />
          <icon-base
            height="12"
            width="12"
            :icon="organizationIconType"
            viewBox="0 1 16 16"
          />
        </template>
      </div>
    </div>
    <div
      v-if="!last"
      class="arrow absolute z-20 mr-0-5 mt-5-5 right-0 w-0 h-0"
      :class="[isCurrentOrPrior ? 'arrow-active' : 'arrow-negative']"
    />
  </div>
</template>
<script setup lang="ts">
import { useScorecardsBase } from "@/hooks/scorecards";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  phaseName: {
    type: String,
    default: null
  },
  currentPhase: {
    type: Number,
    default: 0
  },
  activePhase: {
    type: Number,
    default: 0
  },
  first: {
    type: Boolean,
    default: false
  },
  last: {
    type: Boolean,
    default: false
  },
  index: {
    type: [String, Number],
    default: 0
  },
  showCompletedIcon: {
    type: Boolean,
    default: false
  },
  showDeadIcon: {
    type: Boolean,
    default: false
  },
  disablePhaseChange: {
    type: Boolean,
    default: false
  },
  isManagedByLendflow: {
    type: Boolean,
    default: false
  },
  isManagedByClient: {
    type: Boolean,
    default: false
  },
  clientName: {
    type: String,
    default: ""
  }
});

const { t } = useI18n();
const { isBuilderView } = useScorecardsBase();

const showIconsWithTooltip = ref(false);

const isCurrentOrPrior = computed(
  () => Number(props.index) <= props.currentPhase
);
const isActive = computed(() => Number(props.index) === props.activePhase);

const dynamicIconsStyles = computed(() => {
  if (showIconsWithTooltip.value && isCurrentOrPrior.value) {
    return "visible icons__background--blue pl-5 pr-3";
  } else if (showIconsWithTooltip.value && !isCurrentOrPrior.value) {
    return "visible icons__background--grey pl-5 pr-3";
  } else {
    return "invisible";
  }
});
const organizationIconType = computed(() =>
  isCurrentOrPrior.value ? "organization-white" : "organization"
);
const lendflowIconStyle = computed(() =>
  isCurrentOrPrior.value ? "logo-white" : "logo-blue"
);
const plusIconType = computed(() =>
  isCurrentOrPrior.value ? "plus-no-color" : "plus-blue"
);
const tooltipText = computed(() => {
  if (props.isManagedByLendflow && props.isManagedByClient) {
    return t("DEALS.CLIENT_PHASES.ACCOUNTABLE", {
      name: t("COMMON.BOTH")
    });
  } else if (props.isManagedByLendflow) {
    return t("DEALS.CLIENT_PHASES.ACCOUNTABLE", {
      name: t("COMMON.LENDFLOW")
    });
  } else if (props.isManagedByClient) {
    return t("DEALS.CLIENT_PHASES.ACCOUNTABLE", {
      name: props.clientName
    });
  } else {
    return "";
  }
});

const formattedPhaseName = (phaseName: string) =>
  phaseName === t("DEALS.CLIENT_PHASES.OFFER_OUT")
    ? t("COMMON.OFFER")
    : phaseName;
</script>
<style scoped>
.active {
  @apply bg-primary text-opacity-50;
}
.active:hover {
  @apply text-opacity-100;
}
.inactive {
  @apply bg-medium-grey text-disabled-medium;
}

.highlight {
  @apply text-primary;
}

.highlight-white {
  @apply text-white;
}

.active:hover {
  @apply bg-primary-link-hover;
}
.arrow:after,
.arrow-active:after,
.arrow-negative:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  border-right: 7px solid transparent;
}

.arrow-negative:after {
  border-left: 7px solid white;
}

.arrow:after {
  border-left: 7px solid #f3f4f6;
}
.arrow-active:after {
  border-left: 7px solid #3769d6;
}
.active:hover .arrow-active:after {
  border-left: 7px solid #325ec1;
}

.icons__background--blue {
  background: linear-gradient(
    270deg,
    #3769d6 78.09%,
    rgba(255, 255, 255, 0) 100%
  );
}

.active:hover .icons__background--blue,
.highlight:hover .icons__background--blue {
  background: linear-gradient(
    270deg,
    #325ec1 78.09%,
    rgba(255, 255, 255, 0) 100%
  );
}

.icons__background--grey {
  background: linear-gradient(
    270deg,
    #f3f4f6 78.09%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>
